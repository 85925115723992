import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"titulo",attrs:{"data-aos":"fade-up","data-aos-duration":"1500"}},[_vm._v(" Participa del culto dominical ")]),_vm._m(0),_c(VContainer,[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4",attrs:{"data-aos":"fade-up","data-aos-duration":"1500"}},[_c(VHover,{staticClass:"pa-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"344","height":"365"}},[_c(VImg,{attrs:{"src":require("../../assets/img/Culto 18 Agost.png")}}),_c(VCardText,[_c('h2',[_vm._v("Culto 18 de Agosto 2024 ")]),_vm._v(" ¿Cómo lo encuentro?, ven a escuchar lo que Dios tiene para tu vida. ")]),_c(VFadeTransition,[(hover)?_c(VOverlay,{attrs:{"absolute":"","color":"#ff6347"}},[_c(VBtn,{attrs:{"href":"https://www.youtube.com/watch?v=oaCX91BxOmI","target":"blank"}},[_vm._v("Ver Culto")])],1):_vm._e()],1)],1)]}}])})],1),_c('div',{staticClass:"col-md-4",attrs:{"data-aos":"fade-up","data-aos-duration":"1500","data-aos-delay":"300"}},[_c(VHover,{staticClass:"pa-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"344","height":"365"}},[_c(VImg,{attrs:{"src":require("../../assets/cultos/94.png")}}),_c(VCardText,[_c('h3',[_vm._v("Culto 11 de Agosto ")]),_vm._v(" La luz no debe ser escondida, sino que debe brillar con intensidad para iluminar a los demás. Es absurdo ocultar lo que puede guiar a otros en la oscuridad. ")]),_c(VFadeTransition,[(hover)?_c(VOverlay,{staticClass:"over",attrs:{"absolute":"","color":"#078e3e"}},[_c(VBtn,{attrs:{"href":"https://www.youtube.com/live/L8Ewc55mNBg?si=Jy3lWa-8oBheju7k","target":"blank"}},[_vm._v("Ver Culto")])],1):_vm._e()],1)],1)]}}])})],1),_c('div',{staticClass:"col-md-4",attrs:{"data-aos":"fade-up","data-aos-duration":"1500","data-aos-delay":"600"}},[_c(VHover,{staticClass:"pa-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"344","height":"365"}},[_c(VImg,{attrs:{"src":require("../../assets/cultos/93.png")}}),_c(VCardText,[_c('h2',[_vm._v("Culto 14 de Julio")]),_vm._v(" La gracia de Dios es fundamental para encontrar perdón y libertad de nuestros pecados. A través de su amor, podemos experimentar sanación y restauración en nuestras vidas. ")]),_c(VFadeTransition,[(hover)?_c(VOverlay,{attrs:{"absolute":"","color":"#ff6347"}},[_c(VBtn,{attrs:{"href":"https://www.youtube.com/live/6a6_usMx2gg?si=QRZ6avL_KSvGlck4","target":"blank"}},[_vm._v("Ver Culto")])],1):_vm._e()],1)],1)]}}])})],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{attrs:{"data-aos":"fade-up","data-aos-duration":"1500","datata-aos-delay":"300"}},[_vm._v(" Todos los domingos a las 11:00, puedes participar de forma presencial ("),_c('a',{staticStyle:{"color":"tomato"},attrs:{"href":"#encuentranos"}},[_vm._v("ver ubicación")]),_vm._v(") o de forma online. ")])}]

export { render, staticRenderFns }