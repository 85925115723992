<template>
    <div>
        <Summer />
    </div>
</template>
<script>
    import Summer from "./rotativos/SummerCamp.vue";

    export default {
        name: "App",
        components: {
            Summer,
        }
    };
</script>
  
  