import { render, staticRenderFns } from "./Misiones.vue?vue&type=template&id=3f5a6c66&"
import script from "./Misiones.vue?vue&type=script&lang=js&"
export * from "./Misiones.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports