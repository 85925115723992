<!-- Subpágina de los Grupos-->
<template>
    <div>
        <Reunion />
    </div>
</template>

<script>
    import Reunion from './gruposEnCasa/ReunionesCasa.vue';

export default {
    name: "App",
    components: {
        Reunion
    }
}
</script>