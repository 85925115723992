import { render, staticRenderFns } from "./creativos.vue?vue&type=template&id=5faf69fa&scoped=true&"
var script = {}
import style0 from "./creativos.vue?vue&type=style&index=0&id=5faf69fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5faf69fa",
  null
  
)

export default component.exports