<template>
    <div>
        <Metodos />
    </div>
</template>
<script>
    import Metodos from "./rotativos/creativos.vue";

    export default {
        name: "App",
        components: {
            Metodos,
        }
    };
</script>
  
  