<template>
    <div>
        <Portada />
    </div>
</template>

<script>
import Portada from './misiones/Portada.vue';

export default {
    name: "App",
    components: {
        Portada,
    }
}
</script>